import React from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { CaseStudiesSection } from '@/features/new-landing-page/sections/case-studies';
import { DisruptSection } from '@/features/new-landing-page/sections/disrupt';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { PricingCalculator } from '@/features/new-landing-page/sections/pricing-calculator';
import { CustomersScroller } from '@/features/new-landing-page/components/customers-scroller';
import { ToolsYouNeedToScale } from '@/features/new-landing-page/sections/tools-you-need-to-scale';
import { VSpace } from '@/components/spacing';
import { PricingFaqSection } from '@/features/new-landing-page/sections/pricing-faq';
import { isBrowser } from '@/utils/browser';

export function PricingV2() {
  if (!isBrowser()) {
    return null;
  }

  return (
    <CustomPageWrapper>
      <GlobalStyles />
      <Header />
      <PricingCalculator />
      <VSpace value={8} />
      <CustomersScroller />
      <VSpace className="only-desktop" value={8} />
      <VSpace className="only-mobile" value={4} />
      <ToolsYouNeedToScale />
      <VSpace className="only-desktop" value={8} />
      <VSpace className="only-mobile" value={4} />
      <CaseStudiesSection noCta />
      <PricingFaqSection />
      <DisruptSection ctaHref="https://apps.shopify.com/visually-io?utm_source=Website+&utm_medium=pricing" />
      <Footer />
    </CustomPageWrapper>
  );
}

const CustomPageWrapper = styled(PageWrapper)`
  && {
    section:nth-child(odd) {
      background: #f6f5f4;
    }
  }
`;
